html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #FFF;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #22B0E0 inset !important;
  -webkit-text-fill-color: #FFF !important;
  caret-color: #FFF;
}
